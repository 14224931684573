.scene {
  margin: 0 auto;
  padding: 0;
  width: 97%;
  max-width: 97vh;
  @media (max-width: 767px) and (orientation : portrait) {
    //width: 100%;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 87vmin;
        width: 97vmin;
        max-width: 97vmin;
        margin-top: 2vmin;
  }
  img {
    height: auto;
    width: 97vw;
      @media (max-width: 767px) and (orientation : portrait) {
          //max-width: 100%;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: auto;
        width: 97vmin;
        margin:auto;
      }
    }
}

.scene2 {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 100vh;
  @media (max-width: 767px) and (orientation : portrait) {
    //width: 100%;
  }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: auto;
        width:  auto;
        max-width:  auto;
        margin-top:  auto;
  }
  img {
    height: auto;
    width: 100vw;
      @media (max-width: 767px) and (orientation : portrait) {
          //max-width: 100%;
      }
       @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: auto;
        max-width: 420px;
      }
    }
}

.border {
  border: 2px dashed red;
}


@for $i from 1 through 6 {
  .scene > *:nth-child(#{$i}) {
    //opacity: #{0.15 * $i};
    //border: 2px dashed green;

    margin: 0 auto;
  }
}