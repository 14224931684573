/* ==========================================================================
Fresh Footer
========================================================================== */

footer.footer-dark {
    background: $blue-grey;
    color: $white;
    .columns {
        margin-top: 35px;
    }
    .footer-logo {
        img {
            height: 40px;
        }
    }
    .footer-column {
        .footer-header h3 {
            font-weight:500;
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
        ul.link-list {
            line-height: 40px;
            font-size: 1.1rem;
            a {
                color: $light-blue-grey;
                font-weight: 400;
                transition: all 0.5s;
            }
            :hover {
                color: $smoke-white;
            }
        }
        .level-item .icon {
            color: $secondary;
            transition: all 0.5s;
            :hover {
                color: $smoke-white;
            }
        }
    }
}
footer.footer-light {
    background: #EFF4F7;
    color:$blue-grey;
    padding: 0rem 0rem 0rem;
    .columns {
        margin-top: 35px;
    }
    .footer-logo {
        img {
            height: 115px;
            margin:0 auto;
            display:block;
        }
    }
    .footer-logo-copy {
        color: #a0b0c8;
        font-size: 0.6rem;
        img {
            height: 55px;
            margin:0 auto;
            display:block;
        }
    }
    .footer-column {
        .footer-header h3 {
            font-weight:500;
            font-size: 1.1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
        ul.link-list {
            line-height: 40px;
            font-size: 1.1rem;
            a {
                color: $light-blue-grey;
                font-weight: 400;
                transition: all 0.5s;
                font-size: 1rem;
            }
            :hover {
                color: $secondary;
            }
        }
        .level-item .icon {
            color: $light-blue-grey;
            transition: all 0.5s;
            :hover {
                color: $secondary;
            }
        }
    }
}


ul.link-list {
    line-height: 50px;
    font-size: 1.1rem;
    li{
        display: inline-block;
        margin-right: 15px;
        &:last-child{
            margin-right: 0;
        }
    }
    a {
        color: $light-blue-grey;
        font-weight: 400;
        transition: all 0.5s;
    }
    :hover {
        color: $secondary;
    }
}