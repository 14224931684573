/* ==========================================================================
Product
========================================================================== */

.product{
    position: relative;
        z-index: 9;
        background-color: #fff3d9;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            right: 50%;
            bottom: 0;
            left: 0;
            background-color: #fde8e7;
            }
        
        .product-color-pink{
            background-color: #fde8e7;
        }
        .product-color-yellow{
            background-color: #fff3d9;
        }
        .container {
            width: 100%;
        }
        .columns {
        margin:0;
        //min-height: 100vh;
        &.is-product{
        @media screen and (max-width: 769px), print{
            display: flex;
            .is-4{
                -ms-flex: none;
                flex: none;
                width: 50%;
            }
        }
        }
        &:last-child {
            margin-bottom: 0;
        }
}
}

.product{
    .parfum{
     font-weight: bold;
     display: inline-block;
     position: relative;
     font-size:11px;
     transition: font-size 0.3s;

     &:before_back {
        content: '';
        position: absolute;
        bottom: 1px;
        left: -10%;
        height: 8px;
        background: #fff;
        z-index: -1;
        width: 0%;
        -webkit-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
        -moz-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
        -ms-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
        -o-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
        transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275)
    }

    }
    .desc{
     transition: font-size 0.1s;
     font-size:11px;
    }
}

.is-mpg{
    font-family: 'mpg', sans-serif !important;
	color: $secondary !important;
}

.modal {
  z-index: 99999;
}


.product{
.tilt{  
    z-index:1;    
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 140px;
    background:  url(../images/illustrations/title1.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
   
    color: $secondary;
    text-decoration: none;
    font-size: 2.5em;
    font-weight: 300;
    transform-style: preserve-3d;
    transform: perspective(500px); 
    margin: 30px auto 50px auto;

    &.tilt2{
    background:  url(../images/illustrations/title2.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    }
    
    > span {
      z-index: 1!important;
      font-family: 'mpg', sans-serif !important;
      transform: initial;
      text-shadow: -2px -1px 2px #fff3de;
    }
  
    &:hover {
      &:after{
        transform: translateZ(-100px);
      }
    }
  }
}

  .flex-card {

        .theme-overlay {
                position: absolute;
                top: -350px;
                left: -350px;
                width: 350px;
                height: 350px;
                background: #c2956da8;
                border-radius: 50%;
                transform: scale(1);
                transition: all .3s;
                z-index: 1;
        }

      img{
          max-height: 97px;
          display: block;
          margin: 0 auto;
          
            &:after {
                background-color: rgba(51,51,51,0.5);
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                content: ' ';
                transition: all 0.4s cubic-bezier(0.12, 0.65, 0.4, 1);
                -webkit-transition: all 0.4s cubic-bezier(0.12, 0.65, 0.4, 1);
                opacity: 0;
            }
        }

      .bg-overlay {
        border: 0px solid #fff;
        position: absolute;
        top: 64%;
        left: 4%;
        width: 92%;
        height: 36%;
        z-index: 10;
        transition: border 0.24s cubic-bezier(0, 0, 0.6, 1), opacity 0.2s cubic-bezier(0.5, 0, 1, 0.1);
        -webkit-transition: border 0.24s cubic-bezier(0, 0, 0.6, 1), opacity 0.2s cubic-bezier(0.5, 0, 1, 0.1);
        opacity: 0;
        
     }
    
    &:hover {
        cursor:pointer;
        .theme-overlay {
            transform: scale(5);
        }
        .parfum{
            font-size:14px;
            &:before {
                width: 120%
            }
            
        }
        .desc{
             font-size:12.5px;
            }
        .bg-overlay  {
            transition: border 0.24s cubic-bezier(0, 0, 0.6, 1), opacity 0s ease;
            -webkit-transition: border 0.24s cubic-bezier(0, 0, 0.6, 1), opacity 0s ease;
            
            opacity: 1;
            border-width: 3px;
            border-color: #c2956d;
        }
        img{
              &:after {
                opacity: 1;
              }
          }
    }
    
  }