/* ==========================================================================
Classes to change the feel of bulma buttons
========================================================================== */

// CTA buttons 

.button {
    cursor: pointer;
    transition: all 0.5s;
    &.cta {
        font-family: 'Open Sans', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding: 26px 40px 26px 40px;
    }
    &.is-clear {
        line-height: 0 !important;
    }
    &.rounded {
        border-radius: 500px;
    }
    &.raised:hover {
        box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
        opacity: 0.8;
    }
    &.btn-outlined {
        background: transparent;
    }
    &.signup-button {
        font-size: .9rem;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        padding: 24px 26px;
        width: 130px;
    }
}

.button {
    &.primary-btn {
        outline: none;
        border-color: $primary;
        background-color: $primary;
        color: $white;
        transition: all 0.5s;
        &:hover {
            color: $white;
        }
        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba(79, 193, 234, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(79, 193, 234, 0.2) !important;
            opacity: 0.8;
        }
        &.btn-outlined {
            border-color: $primary;
            color: $primary;
            background-color: transparent; 
            &:hover {
                color: $white;
                background-color: $primary;
            }
        }
    }
    &.secondary-btn {
        outline: none;
        border-color: $secondary;
        background-color: $secondary;
        color: $white;
        transition: all 0.5s;
        &:hover {
            color: $white;
        }
        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba(243, 146, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(243, 146, 0, 0.2) !important;
            opacity: 0.8;
        }
        &.btn-outlined {
            border-color: $secondary;
            color: $secondary;
            background-color: transparent; 
            &:hover {
                color: $white;
                background-color: $secondary;
            }
        }
    }
    &.button.accent-btn {
        outline: none;
        border-color: $accent;
        background-color: $accent;
        color: $white;
        transition: all 0.5s;
        &:hover {
            color: $white;
        }
        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
            opacity: 0.8;
        }
        &.btn-outlined {
            border-color: $accent;
            color: $accent;
            background-color: transparent;
            &:hover {
                color: $white;
                background-color: $accent;
            }
        }
    }
}
.media-left, .media-right {
    width: 150px;
    @media screen and (max-width:768px){
        width: 80px;
}
}
.media.icon-box .media-content .content p .icon-box-title.slide-i {
    @media screen and (max-width:770px){
        font-size: 9px;
    }
}
.media.icon-box .media-content .content p .icon-box-text.slide-i {
    text-align: center;
    @media screen and (max-width:768px){
        font-size: 10px;
    }
}
.media.icon-box {
    width: auto;
    margin: auto;
    max-width: 750px;
    @media screen and (max-width:770px) and (min-width:471px) {
        width: 80%;
    }
    @media screen and (max-width:470px) and (min-width:200px) {
        width: 98%;
    }
}
.text-fruits {
    float: right;
    margin-right: 10%;
    @media screen and (max-width:666px){
        margin-right: 6%;
    }
}
.title.is-2 {
    @media screen and (max-width:610px) and (min-width:400px) {
    font-size: 1.5rem;
    }
    @media screen and (max-width:399px) {
    font-size: 1.3rem;
    }
}
.sitemap ul {
    list-style: circle;
}
.image-ing {
    width: 200px;
    float: right;
    margin-top: -2%;
    margin-right: 3%;
    @media screen and (max-width:770px){
        width: 150px;
        margin-left: 3%;
        margin-right: 0%;
        margin-top: 0%;
}
}
.image-ing-1 {
    width: 150px;
    float: left;
    margin-bottom: 1%;
    @media screen and (max-width:770px){
        width: 100px;
        margin-right: 7%;
        margin-top: 0%;
        margin-bottom: 6%;
}
}

