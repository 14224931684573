/* ==========================================================================
Navbar
========================================================================== */
.navbar-infos {
    position: relative;
    left: 80%;
    top: 19px;
    width: auto;
    height: 34px;
    pointer-events: none;
    transition: 0.1s;
    display: block;
    color:$secondary;
}
//Sidebar animated icon trigger
.navbar-burger {
    position: relative;
    left: 0;
    top: 0;
    //margin: -12px 0px 0px -12px;
    width: 34px;
    height: 34px;
    pointer-events: none;
    transition: 0.1s;
    svg {
        position: absolute;
        top: -4px;
        left: -4px;
        transform: scale(0.07);
        transform-origin: 0 0;
        path {
            stroke: $secondary;
            stroke-width: 40px;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: transparent;
            transition: stroke-dasharray 0.5s;
            &.path1 {
                stroke-dashoffset: 5803.15px; 
                stroke-dasharray: 2901.57px, 2981.57px, 240px;
            }
            &.path2 {
                stroke-dashoffset: 800px; 
                stroke-dasharray: 400px, 480px, 240px;
            }
            &.path3 {
                stroke-dashoffset: 6993.11px; 
                stroke-dasharray: 3496.56px, 3576.56px, 240px;
            }
        }
    }
    &.open {
        svg {
            path {
                &.path1 {
                    stroke-dasharray: 2901.57px, 5258.15px, 240px;
                }
                &.path2 {
                    stroke-dasharray: 400px, 600px, 0px;
                }
                &.path3 {
                    stroke-dasharray: 3496.56px, 6448.11px, 240px;
                }
            }
        }
    }
    .menu-icon-trigger {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        pointer-events: auto;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
    }
    &:hover{
        background-color: transparent;
    }
}


//Navbar
.navbar.is-fresh {
    position: relative;
    min-height: 3rem;
    transition: all .3s;
    z-index: 99;
    .logo {
       position: absolute!important;
       top: 0px;
       left: 0;
       margin: 0!important;
       padding: 0!important;
    }

    .container {
        min-height: 3rem;
    }
    &.no-shadow {
        box-shadow: none !important;
    }
    //Responsive menu icon
    .navbar-burger {
        width: 4rem;
        height: 4rem;
    }
    //Brand
    .navbar-brand {
        h1{
            display: flex;
            padding: 0 0px 0 10px;
            margin: 0;
            font-size: 1.2em;
        }
        min-height: 3rem;
        img {
                max-height: none;
                height: 100px;
                z-index:990;
                    @media (max-width: 768px){
                        height: 110px;
                    }
        }
        //Removing navbar item default hover behaviour
        &:hover {
            .navbar-item {
                background: transparent !important;
            }
        }
    }
    .navbar-end {
        align-items: center;
    }
    //Navbar items
    .navbar-item {
        color: $muted-grey;
        display: inline-block;
        position: relative;
        margin: 0.5rem 1.5rem -1rem;
        padding: 0;
        font-size:21px;
        line-height:initial;
        
        @media only screen and (max-width: 1087px){
            display: block;
            text-align: center;
            font-size: 21px;
            &:hover, &.active {
                background-color: #fafafadb;
            }
        }



        &.is-secondary {
            &:before {
                content: '';
                position: absolute;
                bottom: 7px;
                left: -10%;
                height: 8px;
                background: #EFF4F7;
                z-index: -1;
                width: 0%;
                -webkit-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
                -moz-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
                -ms-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
                -o-transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275);
                transition: all 300ms cubic-bezier(0.175,0.885,0.320,1.275)
            }

            &:hover, &.active {
                //background-color: #fafafadb;
                color: $secondary !important;
                &:before {
                    width:120%;
                }
            }
        }


        &.has-dropdown {
            padding: 10px 0;
            .navbar-link {
                color: $muted-grey;
                &:after {
                    top: 55%;
                    height: 0.5em;
                    width: 0.5em;
                    border-width: 2px;
                    border-color: $muted-grey;
                }
            }
            .navbar-dropdown {
                top: 3.4rem;
                min-width: 220px;
                margin-top: 4px;
                border-top-color: $secondary;
                .navbar-item {
                    padding: 10px 20px;
                }
            }
            &:hover {
                .navbar-link {
                    color: $secondary;
                    &:after {
                        border-color: $secondary;
                    }
                }
            }
        }
        .signup {
            display: block;
            line-height: 0;
            font-size: .9rem !important;
        }
    }

    //Fixed navbar modifier
    &.is-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 4rem !important;
        background: $white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        a {
            color: $blue-grey;
            &:hover {
                color: $primary;
            }
        }
    }
}


.navbar.is-home {
    .navbar-menu {
    background-color: transparent;
    }
    background-color: rgba(255, 255, 255, 0.949)!important;
    @media screen and (min-width: 666px){
    display:none;
    } 
}
.is-hidden-666{
  @media screen and (max-width: 666px){
    display:none;
   }   
}

//Cloned fixed navbar
#navbar-clone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    transform: translateY(-100%);
    z-index: 100;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    &.is-active {
        transform: translateY(0); 
    }
}
