/* ==========================================================================
Hero styles
========================================================================== */

.hero {
    background-color: $light-grey;
    background-color:#fff;
    
&.is-fullheight {
    //min-height: calc(100vh - 64px);
    }

}

.hero-body {
    padding: 0rem;

    .title,
    .subtitle {
        font-family: 'Open Sans', sans-serif;
    }

    .title {
        &.is-bold {
            font-weight: 700;
        }
    }

    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

.hero-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    ul {
        justify-content: space-around;
    }

    li {

        //margin-right: 25px;
        &:last-child {
            //margin-right: 0;
        }

        .home-btn {
            margin: 0 auto;

            min-width: 133px;
            min-height: 133px;

            max-width: 225px;
            max-height: 225px;

            background-color: $secondary;
            -webkit-mask-image: url(../images/illustrations/btn.svg);
            mask-image: url(../images/illustrations/btn.svg);
            color: #fff;

            display: block;
            text-align: center;
            padding-top: 28%;

            span {
                display: block;
                font-weight: normal;
            }

            b {
                display: block;
                font-weight: bold;
            }

            &:hover {
                color: #fff;
                background-color: $primary;
            }


            @media (max-width: 767px) {
                min-width: 110px;
                min-height: 110px;

                max-width: 110px;
                max-height: 110px;
            }
        }
    }


}


.btn-scene {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;


    .btn {
        display: inline-block;
        position: absolute;
        
        a {
        min-width: 133px;
        min-height: 133px;

        max-width: 225px;
        max-height: 225px;

        display: block;
        }
        top: 13%;
        left: 64%;

        &.produit {
            top: 8%;
            left: calc(31% - 135px);
        }

        &.ingredient {
            top: 55%;
            left: calc(28% - 57px);
        }        

        &.contact {
            top: 70%;
            left: calc(69% - 57px);
        }

        @media (max-width: 666px) {

            .home-btn {
                display:none;
            }
            
        }

    }
}

.home-btn {
    margin: 0 auto;
    display: block;
    //background-color: $secondary;
    //-webkit-mask-image: url(../images/illustrations/btn.svg);
    //mask-image: url(../images/illustrations/btn.svg);
    background-image: url('../images/illustrations/btn.png');
    background-size:contain;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        -webkit-mask-image: initial;
        mask-image: initial;
        border-radius:100%;
    }

    color: #fff;

    text-align: center;
    padding-top: calc(50% - 30px);

    span {
        display: block;
        font-weight: normal;
    }

    b {
        display: block;
        font-weight: bold;
    }

    &:hover {
        color: #fff;
        background-image: url('../images/illustrations/btn_hover.png');
        //background-color: #91c6ea;
    }
}


.hero-square-left {
    height: 100%;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
}

.hero-square-right {
    height: 100%;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    right: 0;
}

.is-bg{
    background-image: url(../images/illustrations/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 90% 70px;
    min-height: 500px;
    vertical-align: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px){
        background-image: none;
        background-repeat: repeat;
        background-size: contain;
        background-position-y: 10%;
        min-height: 50px;
    }
    &.is-bg-top {
        min-height: 100%;
    }
    &.is-bg-bt-pr {
        footer {
            @media screen and (max-width: 1024px){
                padding-top: 0px;
            }
        }
    }
    &.is-bg-bt-ing {
        @media screen and (max-width: 666px){
        background-image: url(../images/illustrations/bg1.jpg);
        background-repeat: repeat;
        background-size: contain;
        background-position-y: 10%;
        min-height: 50%;
        }
        @media screen and (max-width: 767px) and (min-width: 667px) {
            background-image: url(../images/illustrations/bg.jpg);
            background-repeat: repeat;
            background-size: contain;
            background-position-y: 9%;
            min-height: 250px;
        }
        @media screen and (max-width: 1024px) and (min-width: 768px) {
            background-image: url(../images/illustrations/bg.jpg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position-y: 100%;
            min-height: 524px;
        }
    }
    &.is-bg-fix{
         background-image:none;
         background-color: #EFF4F7;
    }
    &.is-bg-ing {
        background-color: #fef7dd;
        min-height: 140px;
    }
    &.is-bg-sitemap {
        background-image: url(../images/illustrations/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position: 90% 30px;
        min-height: 200px;
    }
}
.is-savoir{
    position: relative;
        z-index: 9;
        background-color: #EFF4F7;
        padding:0;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
            background-color: #EFF4F7;
            }
            .bg-grey{
                background-color: #EFF4F7;
            }
            .bg-white{
                background-color: #EFF4F7;
            }
    .is-half{
        padding: 2rem;
        padding-top: 0;
    }
    .media-left {
        max-width: 33%;
        img{
            width: 90%;
            margin-top: 35%;
        }
    }
}
.is-ing{
    text-align: center;
    position: relative;
        z-index: 9;
        background-color: #fef7dd;
        padding:0;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
            background-color: #fef7dd;
            }
            .bg-grey{
                background-color: #fef7dd;
            }
            .bg-white{
                background-color: #fef7dd;
            }
    .is-half{
        padding:3rem;
    }
    .media-left {
        max-width: 33%;
        img{
            width:90%;
        }
    }
}

//tilt
.tilt.tilt-ing {       
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 140px;
    background:  url(../images/illustrations/title4.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    z-index: 999;
   
    color: $secondary;
    text-decoration: none;
    font-size: 3.5em;
    font-weight: 300;
    transform-style: preserve-3d;
    transform: perspective(500px); 
    //margin: 20px auto 40px auto;
    margin: 0;

    &.tilt2{
    background:  url(../images/illustrations/title2.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    }

    &.fix{
    background:  none;
    background-color:#eeeeee;
    }
    z-index: 1!important;
    
    > span {
      z-index: 1!important;
      font-family: 'mpg', sans-serif !important;
      transform: translateZ(50px) scale(.6);
      text-shadow: -2px -1px 2px #fff;
      float:none;
      i{
       color: $secondary;
       font-family: BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif !important;
       font-size: 0.7em;
       font-style: normal;
      }
    }
  
    &:hover {
      &:after{
        transform: translateZ(-100px);
      }
    }

    @media (max-width: 768px){
        font-size: 2.5em;
    }
  }
.tilt{       
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 180px;
    background:  url(../images/illustrations/title3.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    z-index: 999;
    color: $secondary;
    text-decoration: none;
    font-size: 3.5em;
    font-weight: 300;
    transform-style: preserve-3d;
    transform: perspective(500px); 
    margin: 20px auto 40px auto;

    &.tilt2{
    background:  url(../images/illustrations/title2.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    }

    &.fix{
    background:  none;
    background-color:#eeeeee;
    }
    z-index: 1!important;
    
    > span {
      z-index: 1!important;
      font-family: 'mpg', sans-serif !important;
      transform: translateZ(50px) scale(.6);
      text-shadow: -2px -1px 2px #fff;
      float:none;
      i{
       color: $secondary;
       font-family: BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif !important;
       font-size: 0.7em;
       font-style: normal;
      }
    }
  
    &:hover {
      &:after{
        transform: translateZ(-100px);
      }
    }

    @media (max-width: 768px){
        font-size: 2.5em;
    }
  }
  
//box
.is-box {
    color: $title-grey;
    span {
        display: block;
    }
    .box-title {
        color: $blue-grey;
        font-size: 1rem;
        font-weight: 600;
        line-height:1;
        @media (min-width: 813px){
            padding-left: 14px;

        }
        i {
            color: #a0b0c8;
            font-size: 0.8rem;
            padding-left: 14px;
        }
    }
    .box-text {
            //color: #a0b0c8;
            font-size: 0.9rem;
            width: 90%;
            text-align: center;
            @media (max-width: 768px){
            //padding-left: 14px;
            text-align: center;
            width: 100%;
            }
    }
}


//modal

/* Effect 1: Fade in and scale up */
.modal {
    &.is-active {
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
          }
        .modal-background {
            opacity: 1;
            background: none;
            @media screen and (max-width: 666px){
                background:  url(../images/illustrations/modal-bck1.png) no-repeat;
                background-size: contain;
                background-position-y: 60%;
                background-position-x: 50%;
            }
            @media screen and (max-width: 767px) and (min-width: 667px) {
                background:  url(../images/illustrations/modal-bck1.png) no-repeat;
                background-size: 60%;
                background-position-y: 60%;
                background-position-x: 50%;
            }
            @media screen and (max-width: 768px) and (min-width: 768px) {
                background:  url(../images/illustrations/modal-bck1.png) no-repeat;
                background-size: 65%;
                background-position-y: 60%;
                background-position-x: 50%;
            }
        }
    }
}

.modal-close {
    background:  url(../images/illustrations/close.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;
    background-color:initial!important;
    right: 50px;
    &:hover{
        &:before, &:after{
            background-color:#9ac849;
        }
    }
}

.modal-content{
    background:  url(../images/illustrations/modal-bck.png) no-repeat;
    background-size: contain;
    background-position-x: 50%;

    min-height: 335px;
    overflow: hidden;
    display: flex;

    max-height: 85%;
    max-width: 85%;
    width: 480px;
    @media screen and (max-width: 768px){
        background:  none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top:20vmin;
    background: url(../images/illustrations/modal-bck.png) no-repeat 0 -50px;
    background-size: contain;
    background-position-x: 50%;
    }
    .box-title {
    font-size: 1.3rem;
    margin-bottom:0px;
    text-align: center;
    padding: 0;
    width: 90%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }

        b{
         line-height: 18px;
         font-size: 18px;
         font-family: BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
         text-transform: uppercase;
        }
        br{
            display: block;
            margin-bottom: 2px;
            font-size:2px;
            line-height: 2px;
        }
    }
    
}


//responsive splashmodal 
@media (max-width: 768px){
.modal {
    //max-width: 90vh;
.columns{
    margin: 0 auto;
}

.img{
    img{
      max-width: 90vw;
      max-height: 90vh;
    }
}
.desc {
    //background-color: #fff;
    padding: 0.5rem !important;
    width: 100%;
    text-align: center;
    //border: solid 2px #eff4f7;
}

}
}

@media (max-height: 414px){
    .modal {
    margin:auto;
   
    .modal-content{
        min-height: 95vh;
        max-width: 95vh;
        margin:auto;
    }
    .columns{
        margin:auto;
    }
    
    .img{
        img{
            max-height: 65vh;
            max-width: 65vw;
            margin:auto;
        }
    }
    
    }
}

@media screen and (max-height: 414px) and (min-width:768px){
    .modal {
    margin:auto;
   
    .modal-content{
        min-height: 85%;
        max-width: 85%;
        margin:auto;

    }
    .columns{
        margin:auto;
    }
    
    .img{
        img{
            max-height: 65vh;
            max-width: 65vw;
            margin:auto;
        }
    }
    
    }
}