/* ==========================================================================
Section Styles
========================================================================== */

//Sections
.section {
    &.section-light-grey {
        background-color: $light-grey;
    }
    &.section-feature-grey{
        background-color: $section-grey;
    }
    &.section-secondary {
        background-color: $secondary;
    }
    &.section-half {
        height: 75vh !important;
    }
    &.is-no-pad{
        padding: 0;
    }
    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;

    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
    
}

//Titles
.title-wrapper {
    max-width: 500px;
    margin: 0 auto;
    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;

    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

//Divider
.divider {
    height: 6px;
    border-radius: 50px;
    background: $secondary;
    width: 90px;
    &.is-centered {
        margin: 0 auto;
    }
}

//Wrapper
.content-wrapper {
    padding: 60px 0;
}


//Pulled image
img.pushed-image {
    margin-top: -29vh;
}

//Icon box
.media.icon-box {
    border-top: none !important;
    .media-content .content p {
        span {
            display: block;
        }
        .icon-box-title {
            color: $blue-grey;
            font-size: 1.2rem;
            font-weight: 600;
            &.t-fab-fr {
                margin-left: 20%;
                @media screen and (min-width: 1280px) {
                    margin-left: 35%;
                }
                @media screen and (min-width: 300px) and (max-width: 1279px){
                    margin-left: 35%;
                }
            }
            &.t-dst-fr {
                margin-left: 35%;
            }
        }
        .icon-box-text {
            text-align: justify;
            color: $title-grey;
            font-size: 1rem;
            font-weight: 400;
            &.img2-exp img{
                float: right;
            }
            &.r-exp {
                text-align: left;
            }
            img {
                width: 33%;
                float: left;
                margin-right: 2%;
                &.img-exp {
                    margin-top: 2%;
                    margin-left: 15%;
                   @media screen and (min-width: 1280px) {
                        width: 18%;
                    }
                    @media screen and (min-width: 1088px) and (max-width: 1279px){
                        width: 28%;
                        margin-top: 2%;
                        margin-left: 5%;
                    }
                    @media screen and (min-width: 982px) and (max-width: 1087px){
                        width: 20%;
                        margin-left: 13%;
                    }
                    @media screen and (min-width: 941px) and (max-width: 981px){
                        width: 28%;
                        margin-left: 5%;
                    }
                    @media screen and (min-width: 769px) and (max-width: 940px){
                        width: 30%;
                        margin-left: 3%;
                    }
                    @media screen and (min-width: 596px) and (max-width: 768px){
                        width: 18%;
                    }
                    @media screen and (min-width: 300px) and (max-width: 595px){
                        width: 33%;
                        margin-left: 0%;
                    }
                }
                &.img-exp-1 {
                    width: 30%;
                    float: right;
                    margin-top: -3%;
                    margin-right: 0%;
                    margin-left: 2%;
                    @media screen and (min-width: 1088px) and (max-width: 1279px){
                        width: 36%;
                        margin-top: 0%;
                    }
                    @media screen and (min-width: 771px) and (max-width: 1088px){
                        width: 36%;
                        margin-top: -1%;
                    }
                    @media screen and (min-width: 596px) and (max-width: 770px){
                        width: 27%;
                        margin-top: -2%;
                    }
                    @media screen and (max-width: 595px){
                        width: 45%;
                        margin-top: -1%;
                    }
                }
            }
        }
    }
}
.media.icon-box.ing-s-3 {
    border-top: none !important;
    @media screen and (max-width: 770px){
    width: 95%;
    }
    .media-content .content p {
        span {
            display: block;
        }
        .icon-box-title {
            color: #2d3266;
            font-size: 1.1rem;
            font-weight: 600;
            text-align: justify;
            @media screen and (max-width: 770px){
                font-size: 1rem;
                text-align: left;
            }
            .i-ing {
                font-size: 0.6rem;
            @media screen and (max-width: 770px){
                font-size: 0.5rem;
            }
            }
        }
    }
}
.media+.media {
    border-top: 1px solid rgba(219,219,219,0.5);
    margin-top: 0rem;
    padding-top: 0rem;
}
.r-exp {
    margin: 0 15%;
    margin-right: 10%;
    @media screen and (max-width: 935px){
        margin: 0 4%;
        margin-right: 1%;
    }
}